<!--
 * ======================================
 * 说明： 评价指标管理
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/9 13:10
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/indicator/list"
    >
      <template #search>
        <Search
          v-model="searchForm"
          :options="searchOptions"
          @onSearch="onFind()"
        ></Search>
      </template>
      <template #action>
        <el-button type="success" @click="handleCreateTarget">新增</el-button>
      </template>
      <template #table-item-subjectList="{ row }">
        {{
          row.subjectList
            .map((item) =>
              item.name == "班主任" ? item.name : item.name + "老师"
            )
            .join("，")
        }}
      </template>

      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleUpdateTarget(row)">修改</el-button>
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteTarget(row)"
          >删除</el-button
        >
      </template>
    </Main>
    <Edit ref="edit" @refresh="onRefresh"></Edit>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import Edit from "./components/edit.vue";
import { columns, searchOptions } from "./config";
export default {
  name: "",
  components: { Main, Search, Edit },
  props: {},
  data() {
    return {
      columns,
      searchOptions,
      searchForm: {},
    };
  },
  computed: {},
  created() {},
  async mounted() {
    await this.onFind();
  },
  methods: {
    onParams() {
      return this.searchForm;
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:26
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      await this.$refs["main"].onFind();
    },
    /**
     *  @actions:  刷新列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 14:40
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onRefresh() {
      this.$refs["main"].onQuery();
    },

    /**
     *  @actions:  新增评价指标
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleCreateTarget() {
      this.$refs["edit"].open({
        mode: 1,
      });
    },

    /**
     *  @actions:  修改评价指标
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:27
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdateTarget(row) {
      this.$refs["edit"].open({
        mode: 2,
        editForm: row,
      });
    },

    /**
     *  @actions:  删除评价指标
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:28
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleDeleteTarget(row) {
      this.$confirm("确定删除该评价指标吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud.get("/indicator/delete", { id: row["id"] }).then(() => {
          this.$message.success("删除成功！");
          this.onRefresh();
        }).catch(err=>{
          this.$message.error(err)
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
