<!--
 * ======================================
 * 说明： 评价指标新增与编辑
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/5/9 13:44
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`${title}指标`"
    confirmText="保 存"
    custom-class="group-manage-dialog"
    width="1000px"
    height="570px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <div class="h-full" style="overflow: hidden" v-loading="loading">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editform"
        label-width="100px"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="指标名称" prop="name">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="指标分值" prop="score">
              <el-input-number
                style="width: 100%"
                :min="0"
                v-model="editForm.score"
              ></el-input-number>
              <!--              <el-select v-model="editForm.score" style="width: 100%">-->
              <!--                <el-option :value="5" label="5分"></el-option>-->
              <!--                <el-option :value="6" label="6分"></el-option>-->
              <!--                <el-option :value="20" label="20分"></el-option>-->
              <!--              </el-select>-->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="评价老师" prop="subjectIds">
              <el-select
                multiple
                filterable
                collapse-tags
                v-model="editForm.subjectIds"
                style="width: 100%"
              >
                <el-option label="班主任" :value="-1"></el-option>
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.name + '老师'"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="指标描述" prop="desc">
              <el-input
                type="textarea"
                :rows="18"
                resize="none"
                v-model="editForm.desc"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "TargetEdit",
  components: { Dialog },
  props: {},
  data() {
    return {
      title: "新增",
      visible: false,
      loading: false,
      editForm: {},
      options: [],
      mode: 1,
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入评价指标名称",
          },
        ],
        score: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value || value === 0) {
                callback(new Error("请输入评价指标分值"));
              } else {
                callback();
              }
            },
          },
        ],
        subjectIds: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value || value.length < 1) {
                callback(new Error("请选择评价老师"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开评价指标新增与编辑框
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:49
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    open(data) {
      this.editForm = {};

      this.mode = data["mode"];
      if (this.mode === 2) {
        this.editForm = data["editForm"];
        this.editForm["score"] = parseInt(this.editForm["score"]);
        if (this.editForm.subjectIds && this.editForm.subjectIds.length) {
          this.editForm.subjectIds = this.editForm.subjectIds.map((item) =>
            parseInt(item)
          );
        }
      }
      this.visible = true;
      this.getSubjectOptions();
    },

    /**
     *  @actions:  获取任课老师列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 14:20
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSubjectOptions() {
      this.$cloud
        .get("/subject/list", {
          pageSize: 999,
          pageIndex: 1,
        })
        .then((res) => {
          if (res.list && typeof res.list == "object" && res.list.length) {
            this.options = res.list;
          } else {
            this.options = [];
          }
        }).catch(err=>{
          this.$message.error(err)
        });
    },

    /**
     *  @actions:  确定或保存
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:46
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      this.$refs["editform"].validate((vaild) => {
        if (vaild) {
          let message = "确定创建当前评价指标吗？",
            url = "/indicator/create";
          if (this.mode === 2) {
            message = "确定保存当前评价指标吗？";
            url = "/indicator/edit";
          }
          this.$confirm(message, "提示", {
            type: "warning",
          }).then(() => {
            this.loading = true;
            this.$cloud
              .post(url, this.editForm)
              .then(() => {
                this.loading = false;
                this.$emit("refresh");
                if (this.mode === 2) {
                  this.$message.success("更新成功！");
                  this.visible = false;
                } else {
                  this.$message.success("创建成功！");
                  this.open({ mode: 1 });
                }
              })
              .catch((err) => {
                this.$message.error(err)
                this.loading = false;
              });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
