/**
 * ======================================
 * 说明： 评价指标管理配置
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/5/9 13:13
 * ======================================
 */
export const columns = [
  {
    field: "name",
    label: "指标名称",
    align: "left",
    width: "160",
  },
  {
    field: "desc",
    label: "内容",
    align: "left",
    width: "auto",
  },
  {
    field: "score",
    label: "分值",
    align: "center",
    width: "100,",
  },
  {
    field: "subjectList",
    label: "评价人",
    align: "left",
    width: "160,",
  },
  {
    field: "created_time",
    label: "创建时间",
    align: "left",
    width: "160",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "140",
  },
];

export const searchOptions = [
  {
    type: "input",
    field: "name",
    label: "指标名称",
    placeholder: "按指标名称查询",
  },
  {
    type: "number",
    field: "score",
    label: "分值",
    placeholder: "按分值查询",
  },
];
